var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "top-img-box" }, [
      _c("img", {
        staticClass: "top-img",
        attrs: { src: _vm.topPicture.pictureAddress, alt: "" }
      })
    ]),
    _c("div", { staticClass: "title" }, [
      _vm.linguisticType == 0
        ? _c("span", { staticClass: "span-title" }, [_vm._v("投资策略")])
        : _vm._e(),
      _vm.linguisticType == 1
        ? _c("span", { staticClass: "span-title" }, [
            _vm._v("Investment Strategy")
          ])
        : _vm._e()
    ]),
    _c("div", [
      _c(
        "div",
        { staticClass: "ul-list" },
        _vm._l(_vm.areas_list, function(v, i) {
          return _c("div", { key: i, staticClass: "li" }, [
            _c("img", { attrs: { src: v.pictureAddress } }),
            _c("div", { staticClass: "word" }, [
              _c("div", { staticClass: "button" }, [_vm._v(_vm._s(v.module))]),
              _c("p", { domProps: { innerHTML: _vm._s(v.note) } })
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }