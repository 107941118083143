//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { scrollInit } from '../utils/scroll';
import { mapState } from 'vuex';
export default {
  name: "investment",

  data() {
    return {
      topPicture: {},
      areas_list: [],
      linguisticType: localStorage.getItem('linguisticType')
    };
  },

  computed: { ...mapState({
      device: state => state.device
    })
  },
  watch: {
    $route(to, from) {
      // this.scrollInit()
      scrollInit(this.$route.query.hash);
    },

    device() {
      let that = this;
      setTimeout(function () {
        this.getMemberImg();
      }, 0);
    }

  },

  created() {
    this.getImg();
    this.getAbout();
  },

  mounted() {
    if (this.$route.query.hash) scrollInit(this.$route.query.hash);
  },

  methods: {
    getImg() {
      this.$http.get(this.$api.listTopPicture, {
        params: {
          pictureType: 2
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            this.topPicture = res.data[0];
          }
        }
      });
    },

    getAbout() {
      this.$http.get(this.$api.getAboutUsList, {
        params: {
          linguisticType: localStorage.getItem('linguisticType')
        }
      }).then(res => {
        console.log(res);
        res.data.forEach(item => {
          if (item.dataType === 2) {
            this.areas_list.push(item);
          }
        });
      });
    }

  }
};